<template>
  <el-config-provider :locale="locale">
    <router-view />
    <Watermark :inputText="mobile" />
  </el-config-provider>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ElConfigProvider } from "element-plus/es";
import { useUserStoreHook } from "./store/modules/user";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import Watermark from "@/components/watermark.vue";
const locale = computed(() => {
  return zhCn;
});
const mobile = computed(() => {
  if (useUserStoreHook().watermarkShow) {
    return useUserStoreHook().userInfo.mobile;
  } else {
    return "";
  }
});
</script>
