export default {
  path: "/emailManage",
  redirect: "/subOption",
  name: "EmailManage",
  meta: {
    icon: "",
    title: "订阅邮件",
    showLink: true,
    rank: 2
  },
  children: [
    {
      path: "/subOption",
      name: "SubOption",
      component: () => import("@/views/emailManage/subOption.vue"),
      meta: {
        title: "订阅选项管理"
      }
    },
    {
      path: "/sendContent",
      name: "SendContent",
      component: () => import("@/views/emailManage/sendContent.vue"),
      meta: {
        title: "内容编辑"
      }
    },
    {
      path: "/emailList",
      name: "EmailList",
      component: () => import("@/views/emailManage/emailList.vue"),
      meta: {
        title: "邮件管理"
      },
      children: [
        {
          path: "/emailList/emailDetail",
          name: "EmailDetail",
          component: () => import("@/views/emailManage/emailDetail.vue"),
          meta: {
            title: "邮箱详情",
            showLink: false
          }
        }
      ]
    }
  ]
} as RouteConfigsTable;
