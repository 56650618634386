export default {
  path: "/exhibitionInfoManagement",
  redirect: "/attributes",
  meta: {
    icon: "memo",
    title: "展会信息管理",
    showLink: true,
    rank: 4
  },
  children: [
    {
      path: "/attributes",
      name: "Attributes",
      component: () =>
        import("@/views/exhibitionInfoManagement/attributes/enter.vue"),
      meta: {
        cache: true,
        title: "商品属性"
      },
      children: [
        {
          path: "/attributes/detail",
          name: "AttributesDetail",
          component: () =>
            import("@/views/exhibitionInfoManagement/attributes/detail.vue"),
          meta: {
            title: "商品详情",
            showLink: false
          }
        }
      ]
    },
    {
      path: "/boothPrice",
      name: "BoothPrice",
      component: () =>
        import("@/views/exhibitionInfoManagement/boothPrice/enter.vue"),
      meta: {
        title: "展位价格"
      }
    }
  ]
} as RouteConfigsTable;
