import { Directive } from "vue";
import { useUserStoreHook } from "@/store/modules/user";
export const buttonPermission: Directive = {
  mounted(el, binding) {
    const { buttonPermissionsList } = useUserStoreHook();
    const index = buttonPermissionsList.findIndex(item => {
      return item.name === binding.value;
    });
    if (index === -1) {
      el.parentElement.removeChild(el);
      return;
    }
    if (buttonPermissionsList[index].availableNum === 0) {
      el.parentElement.removeChild(el);
    }
  }
};
