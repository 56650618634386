export default {
  path: "/logoWall",
  name: "LogoWall",
  component: () => import("@/views/logoWall/index.vue"),
  meta: {
    icon: "memo",
    title: "Logo墙",
    showLink: true,
    rank: 6
  }
} as RouteConfigsTable;
