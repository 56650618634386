import { http } from "@/utils/http";
export type UserResult = {
  success: boolean;
  data: {
    /** 用户名 */
    username: string;
    /** 当前登陆用户的角色 */
    roles: Array<string>;
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

export type RefreshTokenResult = {
  success: boolean;
  data: {
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

/** 登录 */
export const getLogin = (data?: object) => {
  return http.request<UserResult>("post", "/api-uaa/oauth/token", {
    params: data
  });
};

// 获取图形验证码
export const getImgCode = (code?: string) => {
  return http.request<UserResult>("get", `/api-uaa/validata/code/${code}`, {
    responseType: "blob"
  });
};

// 获取验证码
export const getPhoneCode = (mobile?: string) => {
  return http.request<UserResult>(
    "get",
    `/api-user/user/sendLoginSMS?mobile=${mobile}`
  );
};

// 获取小程序登录二维码
export const getWeChatQr = data => {
  return http.request<UserResult>(
    "post",
    `/api-user/wx/miniapp/front/v1.0/loginQRCode`,
    {
      params: data
    },
    { responseType: "blob" }
  );
};

// 轮询登录
export function pollLogin(params: any) {
  return http.request<UserResult>(
    "post",
    `/api-user/wx/miniapp/front/v1.0/queryUserLogin`,
    {
      params
    }
  );
}

/** 刷新token */
export const refreshTokenApi = (data?: object) => {
  return http.request<RefreshTokenResult>("post", "/refreshToken", { data });
};

//获取用户信息
export const getUserInfo = () => {
  return http.request<{ datas; resp_code }>("get", "/api-user/users/current");
};

// 获取加密密钥
export const getPublicKeyApi = () => {
  return http.request<void>("post", "/api-uaa/clients/findPublicKey");
};

// 获取配置
export const configListBefore = (value: string) => {
  return http.request<{ datas: any; resp_code: number }>(
    "get",
    "/api-user/openApi/configListBefore",
    {
      params: { value }
    }
  );
};

// 获取内部员工
export const getInnerAccount = () => {
  return http.request<void>("post", "/api-user/users-anon/getInnerAccount");
};

// 获取按钮权限
export const buttonLimit = () => {
  return http.request<{ datas: any; resp_code: number }>(
    "get",
    "/api-user/menus/findResourceByUser"
  );
};

// 获取手机验证码 MobileCode
export const mobileCode = () => {
  return http.request<{ resp_code: any; datas: any }>(
    "get",
    "/api-user/account/sendPasswordSMS"
  );
};

// 修改密码
export const changePassword = data => {
  return http.request<{ resp_code: any; datas: any }>(
    "post",
    "/api-user/account/modifyPassword",
    {
      data
    }
  );
};

// 退出登录
export const loginOutApi = token => {
  return http.request<{ resp_code: number; datas: any }>(
    "post",
    `/api-uaa/oauth/remove/token?access_token=${token}`
  );
};
