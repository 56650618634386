export default {
  path: "/invitation",
  name: "Invitation",
  component: () => import("@/views/invitation/index.vue"),
  meta: {
    icon: "memo",
    title: "交流邀约名单",
    showLink: true,
    rank: 2,
    children: [
      {
        path: "/invitationDetails",
        name: "InvitationDetails",
        component: () =>
          import("@/views/invitation/details/invitationDetails.vue"),
        meta: {
          icon: "memo",
          title: "交流邀约名单详情"
        }
      }
    ]
  }
} as RouteConfigsTable;
